/* ========
col3
======== */

.c-col3-2{
  display: flex;
  flex-wrap: wrap;


  .c-link-img{
    margin: 0 30px 30px 0;
    @include mq(sp) {
      width: calc(50% - 5px);
      margin: 0 8px 20px 0;
    }
  }



  > *{
    @include mq(pc) {
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include mq(sp) {
      &:nth-child(2n){
        margin-right: 0;
      }
    }
  }

}