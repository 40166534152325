/* ========
c-item-link
======== */

.c-item-link{
  width: 272px;
  height: 68px;
  @include mq(sp) {
    width: 100%;
    height: 48px;
  }
  a{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: palettes(gray);
    color: #fff;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: .08em;
    &.-preparation {
      position: relative;
      pointer-events: none;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(#000, .4);
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    figure{
      width: 68px;
      height: 100%;
      position: relative;
      z-index: 0;
      overflow: hidden;
      flex: 0 0 auto;
      @include mq(sp) {
        width: 48px;
      }
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: none;
        height: 100%;
      }
    }
    p{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: .08em;
      line-height: 1.6;
      position: relative;
      z-index: 0;
      &:after{
        content: '';
        width: 7px;
        height: 7px;
        display: block;
        position: absolute;
        top: 0;
        right: 12px;
        bottom: 0;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: scale(.9,1) rotate(45deg);
        margin: auto 0;
      }
    }
  }
}
