/* ========
breadcrumbs
======== */

.c-breadcrumbs{
  ol{
  }
  li{
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.6;
    font-weight: 500;
    letter-spacing: .08em;
    @include mq(sp) {
      font-size: 1.3rem;
    }
    & + li{
      &:before{
        padding: 0 .33em;
        content: '>';
      }
    }
    a{
      color: palettes(color02);
      @include mq(pc) {
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}