/* ========
item-news
======== */

.c-item-news{
  a{
    display: flex;
    align-items: center;
    @include mq(sp) {
      display: block;
    }
  }
  &__date{
    font-size: 1.3rem;
    background-color: palettes(gray);
    color: #fff;
    font-weight: 700;
    letter-spacing: .08em;
    display: block;
    padding: 0 8px;
    @include mq(sp) {
      display: inline-block;
      font-size: 1.2rem;
      line-height: 1.8;
    }
  }
  &__desc{
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .08em;
    display: block;
    margin: 0 0 0 .5em;
    @include mq(sp) {
      line-height: 1.8;
      font-size: 1.4rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
}