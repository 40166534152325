/* ========
icon
======== */

.c-icon-arrow{
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: gradation(blue, 90deg);
  position: relative;
  z-index: 0;
  &:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: .3em .2em 0 .2em;
    border-color: #fff transparent transparent transparent;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}