/* ========
box-news
======== */

.o-box-news{
  box-shadow: 0 0 18px rgba(#000,.15);
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 24px 0;
  position: relative;
  z-index: 0;
  top: -54px;
  margin: 0 140px -54+40px;
  @include mq(sp) {
    top: -32px;
    margin: 0 0 -32+40px;
    padding: 12px 0 24px;
  }
  &__ttl{
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .08em;
    padding: 0 32px;
    flex: 0 0 auto;
    @include mq(sp) {
      display: none;
    }
  }
  &__content{
    width: 100%;
    border-left: 2px solid #dfe9ed;
    padding: 0 32px;
    @include mq(sp) {
      border: none;
      padding: 0 20px;
    }
    .c-item-news{
      margin: 4px 0;
      @include mq(sp) {
        margin: 0 0 4px;
      }
    }
  }
  .c-btn-small{
    position: absolute;
    right: 0;
    bottom: -14px;
    width: 154px;
    @include mq(sp) {
      width: 124px;
      left: 0;
      margin: 0 auto;
    }
  }
}