/* ========
gnav
======== */

.o-gnav{
  background-color: palettes(gray);
  box-shadow: 0 8px 13px rgba(#000,.15);
  @include mq(sp) {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: gradation(blue,90deg);
    height: calc(100vh - 60px);
    overflow-y: scroll;
  }
  .l-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    @include mq(sp) {
      display: block;
      padding: 32px 25px 64px;
    }
  }
  &__nav{
    ul{
      display: flex;
      align-items: center;
      padding: 10px 0;
      @include mq(sp) {
        display: block;
        padding: 8px 0;
        text-align: center;
      }
      li{
        border-left: 1px solid #fff;
        @include mq(sp) {
          border: none;
          margin: 16px 0;
          line-height: 1.4;
        }
      }
      a{
        color: #fff;
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: .08em;
        display: flex;
        align-items: center;
        padding: 8px 20px;
        @include mq(pc) {
          &:hover{
            opacity: .6;
          }
        }
        @include mq(sp) {
          padding: 0;
          font-size: 1.8rem;
          display: inline-block;
        }
        &:before{
          content: '';
          background: transparent center center no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 15 15'%3E%3Cpath d='M7.5 0a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm0 10.625l-3.464-4.25h6.928L7.5 10.625z' fill='%23fff'/%3E%3C/svg%3E");
          background-size: contain;
          display: block;
          width: 1em;
          height: 1em;
          margin: 0 .5em 0 0;
          @include mq(sp) {
            content: none;
          }
        }
      }
    }
  }
  &__smallbtns{
    display: flex;
    justify-content: space-between;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: .08em;
      color: #fff;
      background-color: rgba(#dfdfdf,.3);
      width: calc(50% - 4px);
      height: 40px;
    }
  }
  .c-btn{
    @include mq(sp) {
      width: 100%;
      margin: 10px 0 0;
      &.-large{
        height: 52px;
      }
    }
  }
  .c-tel-simple{
    margin: 24px 0 0;
  }
}