///*------------------------------
// mixin
// -------------------------------*/

// media query
@mixin mq($DEVICE) {
  @if $DEVICE == "pc" {
      @media (min-width: $bp-sp + 1) {
          @content;
      }
  }
  @else if $DEVICE == "sp" {
      @media (max-width: ($bp-sp)) {
          @content;
      }
  }
}


// color palettes
@function palettes($key, $tone: base) {
  @return map-get( map-get($palettes, $key), $tone);
}

// gradation
@function gradation($color, $rotate: 0deg) {
  @if $color == blue {
    @return linear-gradient($rotate, #64e2b4 0%, #19b4f5 100%);
  }
  @if $color == orange {
    @return linear-gradient($rotate, #f3d900 0%, #ff8f2c 100%);
  }
}

/*------------------------------
表示・非表示切替
-------------------------------*/
.pc{
  @include mq(sp){
    display: none !important;
  }
}
.sp{
  @include mq(pc){
    display: none !important;
  }
}