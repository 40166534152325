/* ========
c-list-pages
======== */

.c-list-pages{
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include mq(sp) {
    flex-wrap: wrap;
  }
  li{
    flex: 1 1 100%;
    height: 60px;
    @include mq(sp) {
      height: 40px;
    }
    & + li{
      @include mq(pc) {
        margin: 0 0 0 20px;
      }
      @include mq(sp) {
        margin: 1px 0 0;
      }
    }
    a{
      display: flex;
      width: 100%;
      height: 100%;
      background: palettes(gray);
      font-size: 1.6rem;
      color: #fff;
      justify-content: center;
      align-items: center;
      letter-spacing: .08em;
      text-align: center;
      @include mq(pc) {
        &:hover{
          background-color: palettes(color02);
        }
      }
      @include mq(sp) {
        font-size: 1.4rem;
      }
    }
    &.-current{
      pointer-events: none;
      a{
        background-color: palettes(color02);
      }
    }
  }
}