/* ========
reason
======== */

.o-reason{
  position: relative;
  z-index: 0;
  padding: 72px 0 88px;
  @include mq(sp) {
    padding: 32px 0 40px;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    min-width: $pc-min-width;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
    background: transparent url($root+'images/top/bg_reason.jpg') center center no-repeat;
    background-size: cover;
    z-index: -1;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    width: 1000px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: rgba(#fff,.85);
    z-index: -1;
    @include mq(sp) {
      width: auto;
      left: 0;
      right: 0;
    }
  }
}