/* ========
ECCUBE
======== */

.bg-load-overlay{
  z-index: -9999 !important;
  pointer-events: none;
}

.ec-layoutRole{
  .ec-layoutRole__contents{
    display: flex;
    @include mq(pc) {
      width: 1200px;
      max-width: 1200px;
      justify-content: space-between;
      padding: 72px 0 80px;
    }
    @include mq(sp) {
      flex-direction: column-reverse;
      padding: 32px 25px 40px;
    }
  }
  @include mq(pc) {
    .ec-layoutRole__mainWithColumn{
      width: 860px;
    }
  }
  .ec-layoutRole__mainBottom{
    margin: 80px 0 0;
    @include mq(sp) {
      margin: 40px 0 0;
    }
  }
  .ec-searchnavRole{
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    max-width: 100%;
  }
}

.ec-orderRole{
  & &__detail{
    > * {
      margin: 0 0 20px;
    }
    h2{
      margin: 0 0 1em;
    }
  }
}

#categoryInfo{
  display: none;
}
#relativeCategory{
  display: none;
}