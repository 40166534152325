/* ========
utility
======== */

.u-mt05{
  margin-top: .5em;
}

.u-mt1{
  margin-top: 1em;
}

.u-mt2{
  margin-top: 2em;
}

.u-mt3{
  margin-top: 3em;
}

.u-mt4{
  margin-top: 4em;
}

.u-mb05{
  margin-bottom: .5em;
}

.u-mb1{
  margin-bottom: 1em;
}

.u-mb2{
  margin-bottom: 2em;
}

.u-mb3{
  margin-bottom: 3em;
}

.u-mb4{
  margin-bottom: 4em;
}

.u-pt05{
  padding-top: .5em;
}

.u-pt1{
  padding-top: 1em;
}

.u-pt2{
  padding-top: 2em;
}

.u-pt3{
  padding-top: 3em;
}

.u-pt4{
  padding-top: 4em;
}

.u-pb05{
  padding-bottom: .5em;
}

.u-pb1{
  padding-bottom: 1em;
}

.u-pb2{
  padding-bottom: 2em;
}

.u-pb3{
  padding-bottom: 3em;
}

.u-pb4{
  padding-bottom: 4em;
}



.u-white{
  color: #fff;
  fill: #fff;
  *{
    color: #fff;
    fill: #fff;
  }
}

.u-center{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.u-right{
  text-align: right!important;
  margin-left: auto!important;
}
