/* ========
lead-pickup
======== */

.o-lead-pickup{
  position: relative;
  z-index: 0;
  padding: 72px 0 88px;
  @include mq(sp) {
    padding: 32px 0;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    min-width: $pc-min-width;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
    background: linear-gradient(135deg,
      rgba(233,240,243,1) 0%,
      rgba(233,240,243,1) 50%,
      rgba(242,247,248,1) 50%,
      rgba(242,247,248,1) 100%);
    z-index: -1;
  }
  .c-slider-box{
    $itemWidth: 280px;
    $itemLength: 5;
    $itemMargin: 16px;
    width: #{($itemWidth + $itemMargin*2)*$itemLength};
    position: relative;
    z-index: 0;
    left: #{(($itemWidth + $itemMargin*2)*$itemLength - $pc-min-width)*-1/2};
    @include mq(sp) {
      width: auto;
      left: 0;
      margin: 0 -25px;
    }
    .c-item-simple{
      margin: 0 $itemMargin;
      @include mq(sp) {
        margin: 0 8px;
      }
    }
  }
}
