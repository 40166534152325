/* ========
search-area
======== */

.c-search-area{
  display: block;
  position: relative;
  z-index: 0;
  input{
    width: 360px;
    height: 46px;
    background-color: #fff;
    padding: 8px 40px 8px 16px;
    @include mq(sp) {
      width: 100%;
      height: 52px;
    }
    &::placeholder{
      color: #c9c9c9;
      font-weight: 400;
    }
  }
  button{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
    padding: 0 10px;
    background: none;
    cursor: pointer;
    @include mq(pc) {
      transition: .4s ease;
      &:hover{
        background-color: palettes(color02);
        .iconSvg{
          fill: #fff;
        }
      }
    }
    @include mq(sp) {
      padding: 0 12px;
    }
    .iconSvg{
      width: 20px;
      height: 20px;
      transition: .4s ease;
    }
  }
}