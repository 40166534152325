/* ========
cart-content
======== */

.o-cart-content{
  .ec-cartHeader__label{
    background-color: palettes(gray,lightest);
  }
  &__foot{
    width: 100%;
    margin: 16px 0 0;
    @include mq(pc) {
      margin: 30px 10% 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .c-note{
      padding: 1.5em;
      background-color: palettes(gray,lightest);
      text-align: justify;
      @include mq(pc) {
        width: 70%;
      }
    }
    .ec-cartRole__actions{
      margin-right: 0;
    }
  }
}