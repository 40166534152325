
/*-------------------------------
WordPressエディタのデフォルト設定
--------------------------------*/

.wp-editor-content {
	* {
		max-width: 100%;
	}
}
//.wp-editor-content
.wp-editor-content {
  // padding: 20px 0;
  strong { font-weight: bold; }
  em { font-style: italic; }
  blockquote {
    // background: #F7F7F7 url(img/blockquote.png) no-repeat 0 0;
    padding: 15px;
    border: 1px solid #CCCCCC;
    color: #212121;
    box-shadow: 1px 1px 2px rgba(204, 204, 204, 0.9), inset 0 1px 0 rgba(255, 255, 255, 0.4);
    clear: both;
  }
  .aligncenter {
      display: block;
      margin: 0 auto;
      text-align: center;
  }
  .alignright { float: right; }
  .alignleft { float: left; }
  img[class*="wp-image-"],
  img[class*="attachment-"] {
      height: auto;
      max-width: 100%;
  }
  .clearfix {
      overflow: hidden;
      zoom: 1;
  }
  .clearfix:after {
      content: "";
      display: block;
      clear: both;
  }


  h1,h2,h3,h4,h5,h6,img,p,ul,ol,blockquote, table {
    margin-bottom: 14px;
  }
  h1,h2,h3,h4,h5,h6 {
    margin-top: 28px;
    &:first-child {
      margin-top: 0;
    }
  }
  h1 {
    border-bottom: 4px solid $color_link;
    color: $color_link;
    padding: 10px;

  }
  h2 {
  }
  h3 {

  }
  a {
    color: $color_link;
  }

  ul,ol {
    padding-left: 1.2em;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }
  ol {
    li {
      list-style-type: decimal;
    }
  }

  table {
    border: 1px solid #CCCCCC;
    th, td {
      border: 1px solid #CCCCCC;
      padding: .5em 1em;

    }
  }

}
