/* ========
link
======== */

.c-link-icon{
  width: 150px;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: .08em;
  text-align: center;
  color: palettes(color02);
  @include mq(sp) {
    width: 76px;
    font-size: 1.3rem;
    line-height: 1.6;
  }
  a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .iconSvg{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: palettes(color02);
    fill: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @include mq(sp) {
      width: 25px;
      height: 25px;
    }
    svg{
      width: 24px;
      height: 24px;
      @include mq(sp) {
        width: 11px;
        height: 11px;
      }
    }
  }
}


.c-link-img{
  width: 380px;
  a{
    display: block;
    position: relative;
    z-index: 0;
    figure{
      border-radius: 4px;
      overflow: hidden;
      img{
        width: 100%;
        transition: 0.8s cubic-bezier(0.11, 0.74, 0.34, 0.93);
      }
    }
    @include mq(pc) {
      &:hover{
        img{
          transform: scale(1.08);
        }
        .c-btn-small{
          span{
            color: rgba(#fff,.6);
          }
        }
      }
    }
    .c-btn-small{
      position: absolute;
      right: 0;
      bottom: -12px;
      span{
        transition: .4s ease;
      }
    }
    &.-preparation {
      pointer-events: none;
      figure {
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(#000, .4);
          width: 100%;
          height: 100%;
        }
        &:after {
          content: '準備中';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 1.6rem;
          letter-spacing: .08em;
          @include mq(sp) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  &:nth-child(3n-1){
    .c-btn-small{
      &.-orange{
        a,span{
          background-color: #f8ba13;
        }
      }
    }
  }

  &:nth-child(3n){
    .c-btn-small{
      &.-orange{
        a,span{
          background-color: #edc705;
        }
      }
    }
  }
}