/* ========
button
======== */

.c-btn{
  width: 256px;
  height: 66px;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: .06em;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    width: 210px;
    height: 47px;
    font-size: 1.4rem;
  }
  a,span,button{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: palettes(color01);
    color: #fff;
    fill: #fff;
    position: relative;
    z-index: 0;
    &[disabled='disabled']{
      pointer-events: none;
      opacity: .5;
    }
  }
  a,button{
    @include mq(pc) {
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background-color: #fff;
        z-index: -1;
        opacity: 0;
        transform: scale(.7,.6);
        transition: opacity .4s ease, transform 0s .4s;
      }
      &:hover{
        // background-color: #fff;
        color: palettes(color01);
        fill: palettes(color01);
        &:before{
          opacity: 1;
          transform: scale(1);
          transition: opacity .4s ease, transform .4s;
        }
        & + .iconSvg{
          fill: palettes(color01);
        }
      }
    }
  }
  .iconSvg{
    width: 1em;
    height: 1em;
    margin: 0 .5em 0 0;
    transition: .4s ease;
  }
  .add-cart{
    padding: 0 0 0 1em;
    & + .iconSvg{
      position: absolute;
      fill: #fff;
      top: 0;
      left: 1.6em;
      bottom: 0;
      margin: auto 0;
      z-index: 1;
      pointer-events: none;
    }
  }


  // color
  &.-gray{
    a,span,button{
      background-color: palettes(gray);
      @include mq(pc) {
        &:hover{
          color: palettes(gray);
          fill: palettes(gray);
        }
      }
    }
  }


  // size
  &.-small{
    width: 164px;
    height: 49px;
    font-size: 1.5rem;
  }
  &.-large{
    width: 303px;
    height: 68px;
    font-size: 2rem;
  }
  &.-full{
    width: 100%;
  }

  // deco
  &.-shadow{
    a,span,button{
      box-shadow: 3px 3px 0 rgba(#000,.14);
    }
  }
}

.c-btn-cart{
  width: 190px;
  height: 40px;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: .08em;
  a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: palettes(color01);
    color: #fff;
    fill: #fff;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #fff;
        z-index: -1;
        opacity: 0;
        transform: scale(.8,.5);
        transition: opacity .4s ease, transform 0s .4s;
      }
      &:hover{
        // background-color: #fff;
        color: palettes(color01);
        fill: palettes(color01);
        &:before{
          opacity: 1;
          transform: scale(1);
          transition: opacity .4s ease, transform .4s;
        }
      }
    }
  }
  .iconSvg{
    width: 23px;
    height: 23px;
    margin: 0 .5em 0 0;
  }
}

.c-btn-small{
  // width: 154px;
  height: 40px;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: .08em;
  @include mq(sp) {
    // width: 124px;
    height: auto;
    font-size: 1.3rem;
  }
  a,span{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: palettes(gray);
    color: #fff;
    fill: #fff;
    position: relative;
    z-index: 0;
    padding: 0 30px 0 20px;
    overflow: hidden;
    @include mq(sp) {
      padding: 4px 18px 4px 8px;
      line-height: 1.6;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 12px;
      bottom: 0;
      width: 7px;
      height: 7px;
      transform: rotate(45deg);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      margin: auto 0;
      transition: .4s ease;
      @include mq(sp) {
        width: 5px;
        height: 5px;
        right: 8px;
      }
    }
  }
  a{
    @include mq(pc) {
      &:hover{
        color: rgba(#fff,.6);
        fill: rgba(#fff,.6);
        &:after{
          border-color: rgba(#fff,.6);
        }
      }
    }
  }
  .iconSvg{
    width: 19px;
    height: 19px;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto 0;
    @include mq(sp) {
      width: 13px;
      height: 13px;
    }
  }

  // text
  &.-center{
    a,span{
      justify-content: center;
      text-align: center;
      padding: 0 22px 0 0;
    }
  }

  // color
  &.-orange{
    a,span{
      background-color: palettes(color03);
    }
  }

  // deco
  &.-icon{
    font-size: 1.4rem;
    a,span{
      &:after{
        content: none;
      }
    }
  }
}

.c-btn-arrow{
  width: 380px;
  height: 108px;
  font-size: 2.4rem;
  letter-spacing: .06em;
  font-weight: 700;
  @include mq(sp) {
    width: 100%;
    height: 78px;
    font-size: 1.8rem;
  }
  a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: palettes(gray);
    color: #fff;
    fill: #fff;
    position: relative;
    z-index: 0;
    padding: 0 0 0 30px;
    @include mq(pc) {
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #fff;
        z-index: -1;
        opacity: 0;
        transform: scale(.8,.5);
        transition: opacity .4s ease, transform 0s .4s;
      }
      &:hover{
        // background-color: #fff;
        color: palettes(gray);
        fill: palettes(gray);
        &:before{
          opacity: 1;
          transform: scale(1);
          transition: opacity .4s ease, transform .4s;
        }
      }
    }
  }
  &__en{
    display: block;
    font-family: $josefin;
    line-height: 1.4;
  }
  &__ja{
    display: block;
    font-size: 1.5rem;
    letter-spacing: .08em;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 1.3rem;
    }
  }
  .iconSvg{
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
    margin: auto 0;
  }
}

.c-btn-tel{
  width: 500px;
  height: 66px;
  font-size: 2.4rem;
  letter-spacing: .06em;
  font-weight: 700;
  @include mq(sp) {
    width: 201px;
    height: 53px;
    margin: 0 5px;
    font-size: 1.9rem;
    line-height: 1.4;
  }
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: $color_text;
    fill: $color_text;
    position: relative;
    z-index: 0;
    box-shadow: 3px 3px 0 rgba(#000,.14);
    @include mq(pc) {
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background-color: $gray;
        z-index: -1;
        opacity: 0;
        transform: scale(.7,.6);
        transition: opacity .4s ease, transform 0s .4s;
      }
      &:hover{
        color: #fff;
        fill: #fff;
        &:before{
          opacity: 1;
          transform: scale(1);
          transition: opacity .4s ease, transform .4s;
        }
      }
    }
    @include mq(sp) {
      display: block;
      padding: 4px 8px;
      text-align: center;
    }
    span{
      font-size: 1.4rem;
      display: block;
      margin: 0 0 0 .8em;
      font-weight: 500;
      @include mq(sp) {
        font-size: 1.2rem;
        margin: 0;
        width: 100%;
      }
    }
  }
  .iconSvg{
    width: .9em;
    height: .9em;
    margin: 0 .2em 0 0;
    @include mq(sp) {
      display: inline-block;
    }
  }
}


.c-btn-menu{
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 4px;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  @include mq(pc) {
    display: none;
  }
  span{
    display: block;
    width: 28px;
    height: 3px;
    background-color: palettes(color02);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    &:before, &:after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 100%;
      background-color: palettes(color02);
    }
    &:before{
      top: -11px;
    }
    &:after{
      bottom: -11px;
    }
  }
}


.c-btn-add{
  width: 86px;
  height: 28px;
  button{
    display: block;
    width: 100%;
    height: 100%;
    font-size: 1.1rem;
    font-weight: 700;
    color: #fff;
    background-color: palettes(gray);
    letter-spacing: .08em;
    text-align: center;
    cursor: pointer;
  }
}

.c-btn-line {
  a {
    display: block;
    margin: 0 0 0 16px;
    cursor: pointer;
    @include mq(pc) {
      transition: opacity .3s;
      &:hover {
        opacity: .7;
      }
    }
    figure {
      width: 160px;
    }
  }
}

.c-btn-barcode {
  margin: 0 0 0 16px;
  figure {
    width: 54px;
  }
}

.c-btn-contact-line {
  width: 310px;
  height: 66px;
  @include mq(sp) {
    width: 72px;
    height: 53px;
    flex: 0 0 auto;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #06c755;
    width: 100%;
    height: 100%;
    box-shadow: 3px 3px 0 rgba(#000, .14);
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .06em;
    z-index: 0;
    cursor: pointer;
    @include mq(pc) {
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background-color: #fff;
        z-index: -1;
        opacity: 0;
        transform: scale(.7,.6);
        transition: opacity .4s ease, transform 0s .4s;
      }
      &:hover{
        color: #06c755;
        &:before{
          opacity: 1;
          transform: scale(1);
          transition: opacity .4s ease, transform .4s;
        }
        figure {
          &:before {
            opacity: 1;
          }
        }
      }
    }
    @include mq(sp) {
      flex-direction: column;
      padding: 4px 0 0;
      font-size: 1.4rem;
    }
    figure {
      position: relative;
      width: 30px;
      margin: 0 .5em 0 0;
      z-index: 1;
      @include mq(sp) {
        width: 20px;
        margin: 0;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #06c755;
        width: 38px;
        height: 38px;
        border-radius: 8px;
        transition: opacity .4s;
        opacity: 0;
        z-index: -1;
      }
    }
  }
}