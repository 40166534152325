/* ========
slider-box
======== */

.c-slider-box{
  &__content{
    .slick-list{
      overflow: visible;
    }
  }
  &__bar{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-family: $josefin;
    font-weight: 700;
    letter-spacing: .08em;
    margin: 32px 0 0;
    @include mq(sp) {
      margin: 24px 0 0;
    }
    &__line{
      width: 400px;
      height: 7px;
      background-color: #fff;
      margin: 0 12px;
      position: relative;
      z-index: 0;
      @include mq(sp) {
        width: 200px;
      }
      span{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: gradation(orange,90deg);
        width: 0;
        transition: .4s ease;
      }
    }
  }
  &__arrow{
    width: 59px;
    height: 59px;
    background-color: palettes(gray);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 40%;
    @include mq(sp) {
      width: 40px;
      height: 40px;
    }
    &[aria-disabled="true"]{
      background-color: palettes(gray,light);
      cursor: auto;
    }
    &:before{
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 9px;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
      @include mq(sp) {
        width: 10px;
        height: 10px;
        border-width: 2px;
        right: 6px;
      }
    }
    &.-prev{
      left: calc(50% - 600px);
      transform: rotate(180deg);
      @include mq(sp) {
        left: 25px;
      }
    }
    &.-next{
      right: calc(50% - 600px);
      @include mq(sp) {
        right: 25px;
      }
    }
  }



  // color
  &.-blue{
    .c-slider-box__bar{
      &__line{
        background-color: #ecf5f8;
        span{
          background: gradation(blue,90deg);
        }
      }
    }
  }
}
