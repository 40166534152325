/* ========
item-box
======== */

.c-item-box{
  width: 360px;
  a, >span{
    display: block;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 18px rgba(#000,.15);
  }
  &__img{
    position: relative;
    z-index: 0;
  }
  &__ttl{
    font-size: 1.6rem;
    color: #fff;
    font-weight: 700;
    letter-spacing: .08em;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0,50%);
    overflow: hidden;
    text-align: center;
    width: auto;
    line-height: 1;
    padding: 8px 16px 8px 8px;
    @include mq(sp) {
      font-size: 1.3rem;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: gradation(blue, 90deg);
      z-index: -1;
      transform: skew(-15deg);
      transform-origin: top right;
    }
  }
  figure{
    border-radius: 4px;
    overflow: hidden;
    img{
      width: 100%;
    }
  }
  &__desc{
    font-size: 1.5rem;
    text-align: justify;
    font-weight: 500;
    letter-spacing: .08em;
    line-height: 1.73;
    padding: 40px 20px 20px;
    @include mq(sp) {
      font-size: 1.4rem;
      padding: 24px 12px 12px;
    }
  }
}
