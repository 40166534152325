/* ========
l-header
======== */
.l-header {
  @include mq(sp) {
    // position: fixed;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: palettes(gray,lightest);
    &.-follow{
      position: fixed;
    }
  }
  input, button, textarea, select {
    // margin: 0;
    // padding: 0;
    // background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
  input, button, textarea, select {
    // margin: 0;
    // padding: 0;
    // background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
}