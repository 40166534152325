///*------------------------------
// 変数
// -------------------------------*/
//色
$color_text: #000;
$gray: #4a4e57;
$color01: #f46d56;
$color02: #5893f4;
$color03: #fd9c24;
$color_link: #000;

//カラーバリエーション
$palettes: (
  color01: (
    base: $color01,
    light: lighten($color01, 10%),
    dark: darken($color01, 10%),
    lightest: lighten($color01, 30%),
  ),
  color02: (
    base: $color02,
    light: lighten($color02, 10%),
    dark: darken($color02, 10%),
    lightest: lighten($color02, 50%),
  ),
  color03: (
    base: $color03,
    light: #f8ba13,
    light2: #edc705,
    dark: darken($color03, 10%),
    lightest: lighten($color03, 50%),
  ),
  gray: (
    base: $gray,
    light: lighten($gray, 30%),
    dark: darken($gray, 10%),
    lightest: #f5f9fa,
  ),
);

//font
$noto: 'Noto Sans JP', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;
$josefin: 'Josefin Sans', $noto;


// レイアウト
$pc-min-width: 1200px;
$sp-min-width: 320px;

//breakpoint記述
//@include mq(sp){width:100%;}
$bp-sp: 768px !default;


$root: '/html/user_data/user/assets/';