/* ========
lead-contact
======== */

.o-lead-contact{
  padding: 78px 0 72px;
  background: linear-gradient(135deg,
    rgba(197,223,110,1) 0%,
    rgba(189,221,117,1) 10%,
    rgba(161,216,131,1) 10%,
    rgba(106,202,177,1) 50%,
    rgba(146,215,198,1) 50%,
    rgba(110,205,228,1) 90%,
    rgba(103,187,233,1) 90%,
    rgba(100,187,236,1) 100%);
  @include mq(sp) {
    padding: 40px 0 32px;
  }
  .c-btn{
    margin: 0 auto;
  }
}