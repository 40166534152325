/* ========
c-counter
======== */

.c-counter{
  width: 62px;
  height: 28px;
  input{
    text-align: right;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #dfdfdf;
  }
}