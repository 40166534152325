/* ========
list-message
======== */

.c-list-message{
  background-color: #fff;
  margin: 0 88px;
  padding: 40px 64px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    margin: 0;
    padding: 10px 16px;
  }
  &:after{
    content: '';
    width: 0;
    height: 0;
    border-width: 28px 24px 0 24px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0 auto;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq(sp) {
      display: block;
    }
  }
  li{
    width: 48.5%;
    padding: 8px 0;
    position: relative;
    z-index: 0;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .08em;
    display: flex;
    align-items: center;
    @include mq(sp) {
      width: 100%;
      align-items: flex-start;
      font-size: 1.3rem;
      line-height: 1.84;
      padding: 12px 0;
      &:last-child{
        &:after{
          content: none;
        }
      }
    }
    .iconSvg{
      width: 1.2em;
      height: 1.2em;
      margin: 0 .8em;
      flex: 0 0 auto;
      @include mq(sp) {
        transform: translate(0,.3em);
        margin: 0 .8em 0 0;
      }
      svg{
        fill: #53c9c3;
      }
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: transparent left top repeat;
      background-image: linear-gradient(90deg,
        rgba(0,0,0,1) 0px,
        rgba(0,0,0,1) 3px,
        rgba(0,0,0,0) 3px,
        rgba(0,0,0,0) 7px);
      background-size: 7px;
    }
  }
}