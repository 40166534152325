/* ========
text
======== */

.c-desc{
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: .08em;
  @include mq(sp) {
    font-size: 1.4rem;
  }
}

.c-note{
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: .08em;
  @include mq(sp) {
    font-size: 1.2rem;
  }
  small {
    font-size: 95%;
  }
}

.c-tel-info{
  color: #181818;
  fill: #181818;
  &__num{
    display: flex;
    align-items: center;
    font-size: 2.6rem;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 1;
    .iconSvg{
      width: 1em;
      height: 1em;
      margin: 0 6px 0 0;
    }
  }
  &__note{
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .08em;
  }
}

.c-tel-simple{
  color: #fff;
  fill: #fff;
  text-align: center;
  &__num{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: .08em;
    line-height: 1;
    .iconSvg{
      width: 1em;
      height: 1em;
      margin: 0 6px 0 0;
    }
  }
  &__note{
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .08em;
  }
}




.c-text-box{
  background-color: #e8eef0;
  position: relative;
  z-index: 0;
  padding: 40px;
  font-size: 1.4rem;
  line-height: 1.72;
  letter-spacing: .08em;
  text-align: justify;
  @include mq(sp) {
    padding: 30px;
  }
  &:before{
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    box-shadow: 3px 3px 0 rgba(#000,.1);
    z-index: -1;
  }
}