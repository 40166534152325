/* ========
archive-news
======== */

.o-archive-news{
  &__content {
    margin: 56px 0 0;
  }
  &__item {
    border-bottom: 1px solid #e8eef0;
    &:first-child {
      border-top: 1px solid #e8eef0;
    }
    &__head {
      display: flex;
      align-items: center;
      position: relative;
      padding: 24px 32px 24px 0;
      cursor: pointer;
      @include mq(sp) {
        padding: 24px 0;
        flex-wrap: wrap;
      }
      .c-icon-arrow {
        position: absolute;
        top: 0;
        right: 16px;
        bottom: 0;
        margin: auto 0;
        @include mq(sp) {
          right: 0;
        }
      }
    }
    &.-active {
      .c-icon-arrow {
        transform: scale(1,-1);
      }
    }
  }
  &__date{
    font-size: 1.3rem;
    background-color: palettes(gray);
    color: #fff;
    font-weight: 700;
    letter-spacing: .08em;
    display: block;
    padding: 0 8px;
    @include mq(sp) {
      display: inline-block;
      font-size: 1.2rem;
      line-height: 1.8;
    }
  }
  &__ttl{
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .08em;
    display: block;
    margin: 0 0 0 .5em;
    @include mq(sp) {
      line-height: 1.8;
      font-size: 1.4rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
  &__desc {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .08em;
    text-align: justify;
    padding: 0 0 24px;
    margin-top: -8px;
    display: none;
  }
}