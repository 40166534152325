/* ========
top
======== */

.home{
  .ec-layoutRole{
    .ec-layoutRole__contents{
      @include mq(pc) {
        display: block;
        width: auto;
        max-width: none;
        padding: 0;
      }
      @include mq(sp) {
        display: block;
        padding: 0;
      }
    }
  }
}