/* ========
intro-category
======== */

.c-intro-category {
  display: flex;
  &__img {
    width: 206px;
    flex: 0 0 auto;
    margin: 0 32px 0 0;
    background-size: cover;
    background-position: center center;
    @include mq(sp) {
      margin: 0 20px 0 0;
      width: 120px;
    }
    &:before {
      content: '';
      display: block;
      padding: 100% 0 0;
    }
  }
  &__textarea {
    width: 100%;
  }
}