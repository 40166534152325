/* ========
bnr
======== */

.c-bnr{
  margin: 40px 0;
  a{
    display: block;
    margin: 16px 0;
    @include mq(pc) {
      transition: .4s ease;
      &:hover{
        opacity: .6;
      }
    }
  }
}

.c-bnr-side{
  margin: 20px 0;
  a{
    display: block;
    @include mq(pc) {
      &:hover{
        figure{
          &:after{
            opacity: .65;
          }
          img{
            transform: scale(1.08);
          }
        }
      }
    }
  }
  figure{
    position: relative;
    z-index: 0;
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#000,.8);
      display: block;
      z-index: 0;
      opacity: .5;
      transition: opacity .4s ease;
    }
    img{
      transition: .8s cubic-bezier(.11,.74,.34,.93);
      width: 100%;
      @include mq(sp) {
        margin: -8% 0;
      }
    }
  }
  figcaption{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    font-size: 2.4rem;
    color: #fff;
    line-height: 1.66;
    font-weight: 700;
    letter-spacing: .08em;
    display: flex;
    align-items: center;
    padding: 0 60px 0 30px;
    @include mq(sp) {
      font-size: 2rem;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #fff;
      top: 0;
      right: 16px;
      bottom: 0;
      margin: auto 0;
      @include mq(sp) {
        width: 30px;
        height: 30px;
      }
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      top: 0;
      right: 26px;
      bottom: 0;
      margin: auto 0;
      border-top: 2px solid #313c46;
      border-right: 2px solid #313c46;
      transform: rotate(45deg);
      @include mq(sp) {
        width: 8px;
        height: 8px;
        right: 28px;
      }
    }
  }
}


.c-bnr-about{
  margin: 20px 0;
  a{
    display: block;
    @include mq(pc) {
      &:hover{
        figure{
          img{
            transform: scale(1.08);
          }
        }
      }
    }
  }
  figure{
    position: relative;
    z-index: 0;
    overflow: hidden;
    &:after{
      content: '';
      display: block;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: #fff url($root+'images/common/logo.jpg') center center no-repeat;
      background-size: 75%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 60px;
      margin: auto;
      @include mq(sp) {
        width: 70px;
        height: 70px;
      }
    }
    img{
      width: 100%;
      transition: .8s cubic-bezier(.11,.74,.34,.93);
      @include mq(sp) {
        margin: -20% 0;
      }
    }
  }
  figcaption{
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: .08em;
    color: #fff;
    padding: 0 20px;
    background: gradation(blue,90deg);
    position: relative;
    z-index: 0;
    @include mq(sp) {
      font-size: 2rem;
      height: 46px;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: 0;
      right: 16px;
      bottom: 0;
      margin: auto 0;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
    }
  }
}