/* ========
mv
======== */

.o-mv{
  position: relative;
  z-index: 0;
  @include mq(sp) {
    height: 335px;
    margin: 0 -25px;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    background-color: rgba(#001d3e, .52);
    transform: translate(-50%,0);
    width: 100vw;
    min-width: $pc-min-width;
    z-index: 0;
    // pointer-events: none;
  }
  &__logo{
    position: absolute;
    z-index: 1;
    width: 104px;
    height: 104px;
    border-radius: 50%;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 10%;
    margin: 0 auto;
    @include mq(pc) {
      display: none;
    }
    a{
      display: flex;
      width: 100%;
      height: 100%;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      img{
        transform: scale(.8);
      }
    }
  }
  &__ttl{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
    z-index: 1;
    width: 789px;
    @include mq(sp) {
      width: 320px;
      top: 66%;
      transform: translate(-50%,-50%);
    }
  }
  &__images{
    .slick-list{
      overflow: visible;
    }
    figure{
      background: transparent center center no-repeat;
      background-size: cover;
      @include mq(pc) {
        background: none !important;
      }
      @include mq(sp) {
        height: 335px;
      }
      img{
        @include mq(sp) {
          opacity: 0;
        }
      }
    }
  }
}