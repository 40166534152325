/* ========
lead-about
======== */

.o-lead-about{
  padding: 80px 0 0;
  position: relative;
  z-index: 0;
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    min-width: $pc-min-width;
    height: 56px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
    background-color: #f2f7f9;
    z-index: -1;
    @include mq(sp) {
      top: 200px;
      height: auto;
    }
  }
  &__btns{
    margin: 40px 0 0;
    ul{
      display: flex;
      justify-content: center;

      @include mq(pc) {
        .c-btn-arrow{
          & + .c-btn-arrow{
            margin-left: 16px;
          }
        }
      }
      @include mq(sp) {
        display: block;
        .c-btn-arrow{
          & + .c-btn-arrow{
            margin-top: 16px;
          }
        }
      }
    }
  }
}
