/* ========
bar-contact
======== */

.o-bar-contact{
  position: fixed;
  background: gradation(blue,90deg);
  left: 0;
  // right: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  min-width: $pc-min-width;
  @include mq(sp) {
    min-width: $sp-min-width;
    // position: relative;
    transition: transform .4s ease;
  }
  .l-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 35px;
    @include mq(sp) {
      justify-content: center;
      padding: 10px 0;
      max-width: 355px;
      width: 100%;
      margin: 0 auto;
    }
  }
  &__desc{
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .08em;
    color: #fff;
    line-height: 1.6;
    @include mq(sp) {
      font-size: 1.5rem;
      text-align: center;
      width: 100%;
      line-height: 1.4;
      margin: 0 0 6px;
      display: none;
    }
  }
  .c-btn{
    width: 296px;
    margin: 0;
    @include mq(sp) {
      width: 72px;
      height: 53px;
      font-size: 1.4rem;
      flex: 0 0 auto;
    }
    a {
      @include mq(sp) {
        flex-direction: column;
        padding: 6px 0 0;
      }
      .iconSvg {
        @include mq(sp) {
          width: 2em;
          margin: 0;
        }
      }
    }
  }
  .c-btn-tel{
    @include mq(sp) {
      flex: 0 0 auto;
    }
  }


  &.-sphidden{
    @include mq(sp) {
      transform: translate(0,100%);
    }
  }
}