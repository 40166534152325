/* ========
title
======== */

.c-ttl{
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1;
  @include mq(sp) {
    font-size: 2.2rem;
  }
  &:after{
    content: attr(data-en);
    font-family: $josefin;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    // background-image: -webkit-linear-gradient(left, #06b730, #00809c);
    background-image: -webkit-+gradation(orange);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: .06em;
    // display: inline-block;
    color: $color03;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
  span{
    display: block;
    margin: 0 0 4px;
    line-height: 1.4;
    @include mq(sp) {
      margin: 0;
    }
  }

  // color
  &.-blue{
    &:after{
      background-image: -webkit-+gradation(blue);
    }
  }
  &.-white{
    color: #fff;
    &:after{
      background-image: none;
      -webkit-background-clip: none;
      -webkit-text-fill-color: #fff;
      color: #fff;
    }
  }
}

.c-ttl-large{
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1;
  @include mq(sp) {
    font-size: 2.6rem;
  }
  &:after{
    content: attr(data-en);
    font-family: $josefin;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1;
    // background-image: -webkit-linear-gradient(left, #06b730, #00809c);
    background-image: -webkit-+gradation(orange);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: .06em;
    // display: inline-block;
    color: $color03;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
  span{
    display: block;
    margin: 0 0 -.25em;
    line-height: 1.4;
    @include mq(sp) {
      margin: 0;
    }
  }

  // color
  &.-blue{
    &:after{
      background-image: -webkit-+gradation(blue);
    }
  }
  &.-white{
    color: #fff;
    &:after{
      background-image: none;
      -webkit-background-clip: none;
      -webkit-text-fill-color: #fff;
      color: #fff;
    }
  }
}

.c-ttl-label{
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.6;
  color: #fff;
  background: gradation(blue,90deg);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  @include mq(sp) {
    // font-size: 2rem;
    height: 52px;
  }
}

.c-ttl-simple{
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 1.6;
  @include mq(sp) {
    font-size: 2rem;
  }
}