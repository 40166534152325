/* ========
list-triple
======== */

.c-list-triple{
  display: flex;
  justify-content: center;
  @include mq(sp) {
    flex-wrap: wrap;
  }
  &__item{
    margin: 0 30px;
    position: relative;
    z-index: 0;
    padding: 0 0 40px;
    @include mq(sp) {
      margin: 24px 16px 0;
      padding: 0 0 32px;
    }
    .iconSvg{
      width: 140px;
      height: 140px;
      background-color: #fff;
      overflow: hidden;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq(sp) {
        width: 109px;
        height: 109px;
      }
      svg{
        width: 65px;
        height: 65px;
        fill: palettes(gray);
      }
    }
    &:nth-child(1){
      @include mq(sp) {
        margin: 0 50%;
      }
      .c-list-triple__num{
        color: #64e2b4;
      }
    }
    &:nth-child(2){
      .c-list-triple__num{
        color: #3dcad6;
      }
    }
    &:nth-child(3){
      .c-list-triple__num{
        color: #19b4f5;
      }
    }
  }
  &__num{
    position: absolute;
    top: -.3em;
    left: 0;
    line-height: 1;
    font-size: 4.7rem;
    font-weight: 700;
    letter-spacing: .05em;
    @include mq(sp) {
      font-size: 3.6rem;
    }
  }
  &__ttl{
    font-size: 2.2rem;
    letter-spacing: .08em;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    white-space: nowrap;
    bottom: 0;
    @include mq(sp) {
      font-size: 1.5rem;
    }
  }
}