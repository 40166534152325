/* ========
head-nav
======== */

.o-head-nav{
  display: flex;
  @include mq(sp) {
    height: 60px;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    width: 150px;
    flex: 0 0 auto;
    @include mq(sp) {
      display: none;
    }
    a{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 16px 22px;
      img{
        width: 100%;
      }
    }
  }
  &__content{
    flex: 1 1 auto;
    @include mq(sp) {
      flex: 0;
      padding: 0 0 0 52px;
    }
  }
  &__head{
    background-image: gradation(blue,90deg);
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .08em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    line-height: 1.5;
    @include mq(sp) {
      display: none;
    }
    >p{
      padding: 0 1em 0 0;
      span{
        display: inline-block;
      }
    }
    nav{
      flex: 0 0 auto;
    }
    ul{
      display: flex;
      align-items: center;
    }
    li{
      display: flex;
      align-items: center;
      &:nth-child(n+2){
        &:before{
          content: '';
          display: block;
          width: 1px;
          height: 1em;
          background-color: #fff;
          margin: 0 1em;
        }
      }
    }
    a{
      display: flex;
      align-items: center;
      .iconSvg{
        width: 1.4em;
        height: 1.4em;
        fill: #fff;
        margin: 0 .5em 0 0;
      }
    }
  }
  &__main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: palettes(gray,lightest);
    padding: 12px 20px;
    @include mq(sp) {
      padding: 0;
    }
  }
  &__info{
    display: flex;
    align-items: center;
    @include mq(sp) {
      display: none;
    }
    .c-tel-info{
      margin: 0 0 0 16px;
    }
  }
  &__ttl{
    display: block;
    width: 206px;
    height: 26.14px;
    @include mq(sp) {
      width: 140px;
      height: 17.7px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  &__setting{
    width: 150px;
    display: flex;
    flex: 0 0 auto;
    @include mq(sp) {
      width: auto;
      max-width: 160px;
      height: 100%;
    }
    .c-link-icon{
      @include mq(sp) {
        height: 100%;
        &:nth-child(2){
          a{
            background-color: #fff;
          }
        }
      }
    }
  }
}