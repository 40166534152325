/* ========
item
======== */

.c-item{
  width: 206px;
  .c-item-taglist{
    margin: 20px 0 -10px;
  }
  a{
    display: block;
    @include mq(pc) {
      &:hover{
        .c-item__img{
          img{
            transform: translate(-50%,-50%)scale(1.08);
          }
        }
        .c-btn{
          span{
            color: rgba(#fff,.6);
          }
        }
      }
    }
  }
  &__img{
    width: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: palettes(gray,lightest);
    &:before{
      content: '';
      display: block;
      width: 100%;
      height: auto;
      padding: 100% 0 0;
      position: relative;
      z-index: -1;
    }
    img{
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: 0.8s cubic-bezier(0.11, 0.74, 0.34, 0.93);
    }
    .c-item-taglist{
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
      margin: 0;
      span{
        background: linear-gradient(90deg, #64e2b4 0%, #19b4f5 100%);
      }
    }
  }
  &__ttl{
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .08em;
    line-height: 1.8;
    // text-align: center;
    margin: 16px 0 8px;
    @include mq(sp) {
      font-size: 1.3rem;
    }
  }
  &__price{
    font-family: $josefin;
    font-size: 2rem;
    letter-spacing: .05em;
    text-align: center;
    margin: 0 0 12px;
    font-weight: 700;
    line-height: 1.2;
    span{
      font-size: 1.5rem;
      letter-spacing: .05em;
      font-weight: 500;
    }
  }
  &__meta{
    background-color: palettes(gray,lightest);
    padding: 4px;
    margin: 10px 0 0;
    @include mq(sp) {
      padding: 8px;
    }
    dl{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mq(sp) {
        display: block;
      }
      & + dl{
        margin: 8px 0 0;
      }
      dt{
        width: 64px;
        font-size: 1.3rem;
        font-weight: 700;
        letter-spacing: .08em;
        padding: 0 0 0 4px;
        @include mq(sp) {
          padding: 0;
        }
      }
      dd{
        display: flex;
        input,select{
          font-size: 1.2rem;
          font-weight: 500;
        }
        .c-btn-add{
          margin: 0 0 0 4px;
          @include mq(sp) {
            flex: 0 0 auto;
          }
        }
      }
    }
    .c-select{
      width: 128px;
      height: 28px;
      @include mq(sp) {
        width: 100%;
      }
      select{
        padding: 4px 30px 4px 8px;
      }
      .c-icon-arrow{
        right: 6px;
      }
    }
  }
  .c-btn{
    width: 100%;
    height: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1.6rem;
    @include mq(sp) {
      font-size: 1.4rem;
      height: 48px;
    }
    span{
      transition: .4s ease;
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 16px;
        bottom: 0;
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        margin: auto 0;
        transition: .4s ease;
      }
    }
  }
}