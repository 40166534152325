/* ========
archive
======== */

.o-archive{
  input, button, textarea, select {
    // margin: 0;
    // padding: 0;
    // background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    @include mq(sp) {
      margin: 0 -10px;
    }
    .c-item{
      margin: 0 6px;
      padding: 0 0 62px;
      position: relative;
      z-index: 0;
      @include mq(pc) {
        &:nth-child(4n-3){
          &:after{
            width: 100%;
            margin-left: 0;
          }
        }
      }
      @include mq(sp) {
        width: calc(50% - 20px);
        margin: 0 10px;
        &:nth-child(2n-1){
          &:after{
            width: 100%;
            margin-left: 0;
          }
        }
      }
      &:after{
        content: '';
        display: block;
        width: calc(100% + 12px);
        height: 2px;
        background-color: #e8eef0;
        margin: 30px 0 30px -12px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @include mq(sp) {
          margin: 30px 0 30px -20px;
        }
      }
    }
  }
}