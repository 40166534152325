/* ========
section
======== */

.s-orange-bar{
  position: relative;
  z-index: 0;
  &:before{
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 18px;
    background: gradation(orange);
    @include mq(sp) {
      width: 4px;
    }
  }
}

.s-blue-bar{
  position: relative;
  z-index: 0;
  &:before{
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 18px;
    background: gradation(blue);
    @include mq(sp) {
      width: 4px;
    }
  }
}