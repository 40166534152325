/* ========
c-detail-item
======== */

.c-detail-item{
  border-top: 2px solid palettes(gray,lightest);
  margin: 30px 0 0;
  padding: 30px 0 0;
  input, button, textarea, select {
    // margin: 0;
    // padding: 0;
    // background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
  &__col{
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      display: block;
    }
  }
  &__images{
    width: 344px;
    @include mq(sp) {
      width: 100%;
    }
  }
  &__content{
    width: 452px;
    @include mq(sp) {
      width: 100%;
    }
  }
  &__tag{
    margin-bottom: 15px;
  }
  &__ttl{
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: .08em;
    line-height: 1.47;
    @include mq(sp) {
      font-size: 2rem;
    }
  }
  &__code{
    margin: .5em 0 1em;
  }
  &__price{
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: .025em;
    line-height: 1.1;
    margin: 16px 0 0;
    @include mq(sp) {
      font-size: 2rem;
      margin: 20px 0 0;
    }
    &.ec-price{
      .ec-price__price{
        padding: 0;
      }
      span{
        font-size: 2.2rem;
        color: palettes(color01);
        @include mq(sp) {
          font-size: 1.8rem;
        }
        span{
          font-size: 4.4rem;
          font-family: $josefin;
          font-weight: 500;
          letter-spacing: .01em;
          @include mq(sp) {
            font-size: 3.6rem;
          }
        }
      }
    }
  }
  &__meta{
    margin: 16px 0 0;
    dl{
      display: flex;
      align-items: center;
      background-color: palettes(gray,lightest);
      padding: 7px;
      & + dl{
        margin: 16px 0 0;
      }
    }
    dt{
      width: 80px;
      padding: 0 4px;
      font-size: 1.5rem;
      font-weight: 700;
      letter-spacing: .08em;
      flex: 0 0 auto;
      @include mq(sp) {
        font-size: 1.3rem;
      }
    }
    dd{
      &.c-select{
        width: 100%;
        height: 48px;
      }
      .c-counter{
        width: 72px;
        height: 48px;
        input{
          border: none;
          @include mq(sp) {
            padding: 0 10px;
            width: 100%;
          }
        }
      }
    }
  }
  &__stock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__note{
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .05em;
    text-align: right;
    margin: 1em 0;
    padding: 0 1em;
    @include mq(sp) {
      font-size: 1.3rem;
    }
  }
  &__btns{
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0;
    @include mq(sp) {
      display: block;
    }
    .c-btn{
      width: 221px;
      height: 62px;
      font-size: 1.6rem;
      &.-full{
        width: 100%;
      }
      @include mq(sp) {
        width: 100%;
        height: 55px;
        font-size: 1.5rem;
        & + .c-btn{
          margin: 10px 0 0;
        }
      }
      .iconSvg{
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
  &__bottom{
    margin: 40px 0 0;
    .c-btn{
      margin: 20px auto 0;
      width: 305px;
      height: 60px;
      font-size: 1.6rem;
      @include mq(sp) {
        width: 100%;
        height: 55px;
        font-size: 1.5rem;
      }
      .iconSvg{
        width: 1.5em;
        height: 1.5em;
      }
    }
    .c-text-box{
      margin-top: 32px;
      @include mq(sp) {
        margin-top: 24px;
      }
    }
  }
  form{
    & + form{
      @include mq(pc) {
        height: 0;
        .c-detail-item__btns{
          margin: 0;
          height: 100%;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }
  }

  .c-detail-item__cadbtnArea{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 32px;

    .c-btn{
      width: calc(25% - 8px);
      height: 62px;
      margin: unset;
      a{
        display: flex;
        gap: 0.5em;
        align-items: center;
        padding: 1em;
      }

      .iconSvg{
        width: 24px;
        height: 24px;
        margin-right: unset;
      }
      p{
        text-align: unset;
        width: calc(100% - 24px - 1em);
        background-color: unset;
        font-size: 1.6rem;
        line-height: 1;
      }

      @include mq(sp) {
        width: calc(50% - 5px);
      }
    }
  }

  .c-detail-item__accordionArea{
    padding: 0.5em;
    margin-top: 32px;
    background-color: #e8eef0;

    ul{
      position: relative;
      background-color: white;
      box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);

      &::before{
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        background-color: #fff;
        box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
        z-index: -1;
      }
      ul{
        box-shadow: unset;
      }
      li{
        span{
          width: 100%;
        }
        a{
          display: flex;
          align-items: center;
          gap: 5px;
          transition: 0.5s;
          .iconSvg{
            width: 24px;
            height: 24px;
          }
          &:hover{
            opacity: 0.7;
          }
        }
      }
    }
  }
  
}
