/* ========
c-result-search
======== */

.c-result-search{
  background-color: palettes(gray,lightest);
  padding: 10px 10px 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sp) {
    padding: 10px;
  }
  input, button, textarea, select {
    // margin: 0;
    // padding: 0;
    // background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
  &__text{
    font-size: 1.6rem;
    letter-spacing: .08em;
    line-height: 1;
    @include mq(sp) {
      font-size: 1.4rem;
    }
    span{
      font-size: 2.4rem;
      letter-spacing: .08em;
      @include mq(sp) {
        font-size: 2rem;
      }
    }
  }
}