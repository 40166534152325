/* ========
slider-photo
======== */

.c-slider-photo{
  width: 344px;
  @include mq(sp) {
    width: 100%;
  }
  &__main{
    position: relative;
    z-index: 0;
    ul{
      li{
        @include mq(pc) {
          cursor: pointer;
        }
        background: transparent center center no-repeat;
        background-size: contain;
        &:before{
          content: '';
          display: block;
          padding: 100% 0 0;
        }
      }
    }
    img{
      width: 100%;
      opacity: 0;
      display: none;
    }
  }
  &__arrow{
    width: 26px;
    height: 26px;
    background-color: palettes(gray);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto 0;
    @include mq(sp) {
      width: 40px;
      height: 40px;
    }
    &[aria-disabled="true"]{
      background-color: palettes(gray,light);
      cursor: auto;
    }
    &:before{
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 3px;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
      @include mq(sp) {
        width: 10px;
        height: 10px;
        right: 4px;
      }
    }
    &.-prev{
      transform: rotate(180deg);
      left: -13px;
    }
    &.-next{
      right: -13px;
    }
  }
  &__sub{
    ul{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
      @include mq(sp) {
        flex-wrap: nowrap;
        overflow-x: scroll;
      }
    }
    li{
      width: 108px;
      margin: 10px 5px 0;
      cursor: pointer;
      position: relative;
      z-index: 0;
      background-color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq(sp) {
        width: 64px;
        flex: 0 0 auto;
      }
      &.slick-active{
        img{
          opacity: 1;
        }
      }
      img{
        opacity: .8;
        transition: opacity .2s ease;
      }
    }
  }
}
