/* ========
nav-side
======== */

.o-nav-side{
  background-color: #f2f7f9;
  padding: 25px;
  @include mq(sp) {
    padding: 15px;
  }

  @include mq(pc) {
    max-height: calc(100vh - 140px);
    overflow-y: scroll;
  }
  .c-acdn{
    margin: 12px 0 16px;
    @include mq(sp) {
      margin: 15px 0;
    }
  }
}