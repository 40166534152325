/* ========
c-item-taglist
======== */

.c-item-taglist{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  span{
    padding: 5px 8px;
    background-color: palettes(color02);
    background: #19b4f5;
    color: #fff;
    line-height: 1;
    border-radius: 3px;
  }
  &.-small{
    span{
      font-size: 1.2rem;
    }
  }
}