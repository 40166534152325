/* ========
l-inner
======== */
.l-inner {
  max-width: $pc-min-width;
  margin: 0 auto;
  @include mq(sp){
    max-width: 100%;
    padding: 0 25px;
  }
  &.-col{
    display: flex;
    justify-content: space-between;
    padding: 112px 0 80px;
    @include mq(sp) {
      flex-direction: column-reverse;
      padding: 32px 25px 40px;
    }
  }
  // &.-lower{
  //   padding: 112px 0 80px;
  //   @include mq(sp) {
  //     padding: 32px 25px 40px;
  //   }
  // }
}