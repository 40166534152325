/* ========
pager
======== */

.c-pager{
  ul{
    display: flex;
    justify-content: center;
  }
  li{
    min-width: 40px;
    height: 40px;
    margin: 0 5px;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: .08em;
      color: #fff;
      background-color: palettes(gray);
      padding: 0 8px;
    }
  }
}