/* ========
modal-zoom
======== */

.c-modal-zoom{
  display: none;
  // opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000,.8);
  }
  &__img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    @include mq(sp) {
      width: 100%;
      max-width: auto;
      padding: 0 20px;
    }
  }
  &__close{
    width: 41px;
    height: 41px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    @include mq(pc) {
      transition: .4s ease;
      &:hover{
        opacity: .6;
      }
    }
    &:before, &:after{
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &:before{
      transform: rotate(-45deg);
    }
    &:after{
      transform: rotate(45deg);
    }
  }
}