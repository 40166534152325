/* ========
o-grp-link
======== */

.o-grp-link{
  display: flex;
  flex-wrap: wrap;
  padding: 22px 0;
  border-top: 2px solid #e8eef0;
  border-bottom: 2px solid #e8eef0;
  @include mq(sp) {
    display: block;
  }
  .c-item-link{
    margin: 10px 20px 10px 0;
    @include mq(pc) {
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include mq(sp) {
      margin-right: 0;
    }
  }
}
