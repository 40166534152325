/* ========
product_page
======== */

.product_page{
  .form-control{
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}