/* ========
l-side
======== */
.l-side {
  width: 300px;
  @include mq(pc) {
    position: sticky;
    top: 30px;
  }
  @include mq(sp) {
    width: auto;
    margin: 72px 0 0;
  }
  input, button, textarea, select {
    // margin: 0;
    // padding: 0;
    // background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
}