/* ========
guide-content
======== */

.o-guide-content{
  padding: 40px 0 0;
  @include mq(sp) {
    margin: 0 -25px;
  }
  .c-list-pages{
    margin: 0 0 32px;
  }
  &__label{
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: .08em;
    text-align: center;
    background-color: #e8eef0;
    padding: .2em 0;
    line-height: 2;
    @include mq(sp) {
      font-size: 2.2rem;
    }
  }
  &__box{
    background-color: palettes(gray,lightest);
    padding: 50px;
    @include mq(sp) {
      padding: 10px;
    }
    h3{
      font-size: 2.6rem;
      font-weight: 700;
      letter-spacing: .08em;
      line-height: 1.5;
      margin: 1.8em 0 .75em;
      @include mq(sp) {
        font-size: 2rem;
      }
      &:first-child{
        margin-top: 0;
      }
    }
    h4{
      font-size: 1.8rem;
      font-weight: 700;
      letter-spacing: .08em;
      line-height: 1.5;
      // color: palettes(color02);
      margin: 0 0 .4em;
      @include mq(sp) {
        font-size: 1.6rem;
      }
    }
    p{
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: .08em;
      text-align: justify;
      line-height: 1.8;
      @include mq(sp) {
        font-size: 1.3rem;
      }
    }
    small{
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .08em;
      color: palettes(gray,light);
      line-height: 1.8;
      display: block;
      text-align: justify;
      @include mq(sp) {
        font-size: 1.2rem;
      }
    }
    hr{
      border-color: #e8eef0;
      margin: 20px 0;
      &.-noline{
        border: none;
        margin: 20px 0 0;
      }
    }
    ul{
      margin: .5em 0;
      li{
        font-size: 1.5rem;
        line-height: 1.6;
        &:before{
          content: '・';
        }
      }
    }
    ol{
      margin: .5em 0;
      counter-reset: olNum;
      li{
        font-size: 1.5rem;
        line-height: 1.6;
        &:before{
          counter-increment: olNum 1;
          content: counter(olNum);
        }
      }
    }
    .-tablewrap{
      @include mq(sp) {
        overflow-x: scroll;
      }
      table{
        @include mq(sp) {
          width: 900px;
        }
      }
    }
    table{
      width: 100%;
      table-layout: fixed;
      thead{
        th{
          &:first-child{
            @include mq(pc) {
              width: 260px;
            }
          }
        }
      }
      tr{
        border-bottom: 1px solid #9aa0ae;
        line-height: 1.6;
      }
      th{
        background-color: #f2f7f8;
        padding: .5em 1em;
        font-weight: 500;
        font-size: 1.8rem;
        text-align: left;
        @include mq(sp) {
          font-size: 1.6rem;
        }
      }
      td{
        padding: .5em 1em;
        font-size: 1.5rem;
        text-align: left;
        @include mq(sp) {
          font-size: 1.3rem;
        }
      }
    }
    a{
      color: palettes(color02);
      &:visited{
        color: #6758f4;
      }
      @include mq(pc) {
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  &__inner{
    background-color: #fff;
    padding: 50px;
    @include mq(sp) {
      padding: 20px;
    }
  }
}
