/* ========
.o-text-area
======== */

.o-text-area{
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2;
  @include mq(sp) {
    font-size: 1.4rem;
  }
  *{
    letter-spacing: .1em;
    text-align: justify;
    &:first-child{
      margin-top: 0;
    }
  }
  p{
    margin: 1em 0;
  }
  h1,h2,h3,h4,h5,h6{
    span{
      position: relative;
      z-index: 0;
      display: inline-block;
      padding: 0 0 0 24px;
      &:before{
        content: '';
        display: block;
        background: gradation(blue);
        width: 4px;
        position: absolute;
        top: .26em;
        left: 0;
        bottom: .26em;
        z-index: -1;
      }
    }
  }
  h1,h2{
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.8;
    margin: 1.5em 0 .5em;
    @include mq(sp) {
      font-size: 1.8rem;
    }
  }
  h3,h4{
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.8;
    margin: 1.5em 0 .5em;
    @include mq(sp) {
      font-size: 1.6rem;
    }
  }
  h5,h6{
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.8;
    margin: 1.5em 0 .5em;
    @include mq(sp) {
      font-size: 1.6rem;
    }
  }
  a{
    text-decoration: underline;
    line-height: 1.4;
    @include mq(pc) {
      transition: color .4s ease;
      &:hover{
        color: palettes(color01);
      }
    }
    @include mq(sp) {
      word-break: break-all;
    }
  }
  ul,ol{
    margin: 1.5em 0 .5em;
  }
  li{
    display: flex;
  }
  ul{
    >li{
      &:before{
        content: '・';
      }
    }
    ul{
      >li{
        &:before{
          content: '◦';
        }
      }
    }
  }
  ol{
    counter-reset: listBasic;
    >li{
      &:before{
        counter-increment: listBasic 1;
        content: counter(listBasic)'.';
      }
    }
  }
  table{
    line-height: 1.8;
    table-layout: fixed;
    margin: 3em 0;
    position: relative;
    z-index: 0;
    background-color: #fff;
    tr{
      border-top: 1px solid rgba(palettes(gray,light),.5);
      border-bottom: 1px solid rgba(palettes(gray,light),.5);
      @include mq(sp) {
        display: block;
        width: 100%;
        border-bottom: none;
      }
    }
    th,td{
      padding: 1em 1.5em;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: .5em 20px;
      }
    }
    th{
      font-weight: 700;
      font-size: 1.8rem;
      background-color: palettes(gray,lightest);
      @include mq(pc) {
        width: 25%;
      }
      @include mq(sp) {
        font-size: 1.6rem;
      }
    }
    td{
      font-weight: 500;
      @include mq(sp) {
        padding: 1em 20px;
      }
    }
  }
}