/* ========
foot-nav
======== */

.o-foot-nav{
  background-color: #f2f7f9;
  padding: 40px 0 140px;
  @include mq(sp) {
    padding: 40px 0 100px;
  }
  &__nav{
    margin: 0 0 24px;
    ul{
      display: flex;
      justify-content: center;
      @include mq(sp) {
        flex-wrap: wrap;
      }
    }
    &__main{
      margin: 0 0 16px;
      li{
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: .08em;
        @include mq(pc) {
          &:nth-child(n+2){
            &:before{
              content: '';
              display: block;
              width: 1px;
              height: 1em;
              background-color: #000;
              flex: 0 0 auto;
              margin: 0 1em;
            }
          }
        }
        @include mq(sp) {
          margin: 0 -1px 0 0;
          &:before, &:after{
            content: '';
            display: block;
            width: 1px;
            height: 1em;
            background-color: #000;
            flex: 0 0 auto;
          }
          &:before{
            margin: 0 1em 0 0;
          }
          &:after{
            margin: 0 0 0 1em;
          }
        }
        a{
          display: block;
        }
      }
    }
    &__sub{
      li{
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: .08em;
        &:nth-child(n+2){
          margin-left: 1em;
        }
        a{
          &:before{
            content: '・';
          }
        }
      }
    }
  }
  &__info{
    text-align: center;
    ul {
      display: flex;
      justify-content: center;
      margin: 8px 0 12px;
      @include mq(sp) {
        margin: 8px 0;
      }
      li {
        margin: 0 20px 0 0;
        &:last-child {
          margin: 0;
        }
        a {
          display: block;
          cursor: pointer;
          @include mq(pc) {
            transition: opacity .3s;
            &:hover {
              opacity: .7;
            }
          }
          figure {
            width: 32px;
          }
        }
      }
    }
  }
  &__company{
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: .08em;
  }
  &__meta{
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    line-height: 1.6;
    @include mq(sp) {
      display: block;
    }
    p{
      margin: .5em 1em;
    }
  }
  .copyright{
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: .06em;
    display: block;
    @include mq(sp) {
      line-height: 1.6;
    }
  }
}