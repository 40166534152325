/* ========
o-related-item
======== */

.o-related-item{
  margin: 80px 0 0;
  @include mq(sp) {
    margin: 64px 0 0;
  }
  .o-archive{
    .c-item{
      @include mq(pc) {
        padding-bottom: 0;
        &:after{
          content: none;
        }
      }
    }
  }
}