/* ========
js module
======== */

.iconSvg{
  display: block;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}