/* ========
lead-service
======== */

.o-lead-service{
  position: relative;
  z-index: 0;
  padding: 72px 0 88px;
  .c-slider-box{
    $itemWidth: 360px;
    $itemLength: 5;
    $itemMargin: 16px;
    width: #{($itemWidth + $itemMargin*2)*$itemLength};
    position: relative;
    z-index: 0;
    left: #{(($itemWidth + $itemMargin*2)*$itemLength - $pc-min-width)*-1/2};
    @include mq(sp) {
      width: auto;
      left: 0;
      margin: 0 -25px;
    }
    .c-item-box{
      margin: 0 $itemMargin;
      @include mq(sp) {
        margin: 0 16px;
      }
    }
  }
}
