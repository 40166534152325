/* ========
c-select
======== */

.c-select{
  width: 250px;
  height: 46px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    width: 175px;
    height: 40px;
  }
  select{
    width: 100%;
    height: 100%;
    display: block;
    background-color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .08em;
    padding: 0 40px 0 20px;
    padding: 0 40px 0 16px;
  }
  .c-icon-arrow{
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
  }
}