/* ========
accordion
======== */

.c-acdn{
  background-color: #e8eef0;
  &__parent{
    a {
      background-color: #fff;
      width: calc(100% - 2px);
      box-shadow: 2px 2px 0 rgba(#000,.15);
      position: relative;
      z-index: 0;
      height: 60px;
      font-size: 1.6rem;
      line-height: 1.6;
      display: flex;
      align-items: center;
      letter-spacing: .08em;
      font-weight: 700;
      padding: 0 32px 0 20px;
      cursor: pointer;
      @include mq(pc) {
        &:hover{
          &:before{
            opacity: .25;
          }
        }
      }
      @include mq(sp) {
        height: 55px;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: gradation(blue,90deg);
        opacity: 0;
        z-index: -1;
        transition: opacity .4s ease;
      }
      &.-preparation {
        color: #999;
        pointer-events: none;
      }
      .c-icon-arrow{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 16px;
        margin: auto 0;
      }
    }
  }
  &__children{
    // display: none;
    ul{
      padding: 12px 20px;
    }
    li{
      & + li{
        border-top: 1px solid #c2c9cc;
      }
      &.-has-child{
        position: relative;
        & > span{
          display: block;
          width: 16px;
          height: 40px;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          &:after{
            content: '';
            width: 7px;
            height: 7px;
            display: block;
            position: absolute;
            top: 0;
            right: 2px;
            bottom: 0;
            border-top: 2px solid palettes(gray);
            border-right: 2px solid palettes(gray);
            transform: scale(.9,1) rotate(135deg);
            margin: auto 0;
          }
          &.-active{
            &::after{
              transform: scale(.9,1) rotate(-45deg);
            }
            & + .c-acdn__grandchildren{
              display: block;
            }

          }
        }
      }
    }

    a{
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: .08em;
      display: block;
      line-height: 1.6;
      padding: 8px 16px 8px 0;
      position: relative;
      z-index: 0;
      &.-preparation {
        color: #999;
        pointer-events: none;
      }
      &:after{
        // content: '';
        // width: 7px;
        // height: 7px;
        // display: block;
        // position: absolute;
        // top: 0;
        // right: 2px;
        // bottom: 0;
        // border-top: 2px solid palettes(gray);
        // border-right: 2px solid palettes(gray);
        // transform: scale(.9,1) rotate(45deg);
        // margin: auto 0;
      }

    }
  }
  &__grandchildren{
    display: none;
    ul{
      padding: 8px 0 16px;
      border-top: 1px solid #c2c9cc;
      li{
        border-top: none;
      }
    }
    a{
      font-size: 1.3rem;
      padding: 4px 0;
    }
  }


  // &.-active{
  //   .c-acdn__parent{
  //     .c-icon-arrow{
  //       transform: scale(1,-1);
  //     }
  //   }
  // }
}
