/* ========
item-simple
======== */

.c-item-simple{
  width: 280px;
  @include mq(sp) {
    width: 225px;
  }
  a{
    display: block;
    background-color: #fff;
    padding: 24px 24px 84px;
    position: relative;
    z-index: 0;
    box-shadow: 0 0 18px rgba(#000,.15);
    @include mq(pc) {
      &:hover{
        img{
          transform: translate(-50%,-50%)scale(1.08);
        }
        .c-btn{
          span{
            color: rgba(#fff,.6);
          }
        }
      }
    }
    @include mq(sp) {
      padding: 10px 10px 64px;
    }
  }
  figure{
    width: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: palettes(gray,lightest);
    &:before{
      content: '';
      display: block;
      width: 100%;
      height: auto;
      padding: 100% 0 0;
      position: relative;
      z-index: -1;
    }
    img{
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: 0.8s cubic-bezier(0.11, 0.74, 0.34, 0.93);
    }
    .c-item-taglist{
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
  &__name{
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .08em;
    line-height: 1.625;
    margin: 16px 0 0;
    // text-align: center;
    @include mq(sp) {
      font-size: 1.5rem;
    }
  }
  &__price{
    text-align: center;
    font-size: 2rem;
    letter-spacing: .05em;
    font-weight: 700;
    line-height: 1;
    margin: 8px 0 0;
    .-val{
      font-family: $josefin;
    }
    .-tax{
      font-size: 1.5rem;
      letter-spacing: .05em;
    }
  }
  .c-btn{
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1.6rem;
    @include mq(sp) {
      font-size: 1.4rem;
      height: 48px;
    }
    span{
      transition: .4s ease;
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 16px;
        bottom: 0;
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        margin: auto 0;
        transition: .4s ease;
      }
    }
  }
}
